<template>
  <b-card class="emission-card-border border-secondary">
    <b-row class="mb-2">
      <b-col cols="6" class="d-flex justify-content-start align-items-center">
        <b-img :src="emissaoImage" class="mr-1" />
        <div class="d-flex flex-column justify-content-start">
          <span class="mb-25"> Detalhes da emissão </span>
          <div class="d-flex">
            <h2 class="mb-0 font-weight-bolder mr-1">
              {{ emissao.tipo_emissao }} - {{ emissao.nome_fantasia }}
            </h2>
            <b-img src="@/assets/custom-icons/coroa.svg" height="28" />
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="d-flex flex-column justify-content-end align-items-end">
        <h4 class="d-flex align-items-end">
          Status:
          <b-badge
            :class="`ml-1 badge badge-light-${emissao.is_ativo ? 'success' : 'danger'} border-${
              emissao.is_ativo ? 'success' : 'danger'
            }`"
          >
            <span class="text-dark">
              {{ emissao.is_ativo ? 'Ativo' : 'Inativo' }}
            </span>
          </b-badge>
        </h4>
        <h4 class="mb-0 d-flex align-items-end">
          Valor da emissão:
          <h2 class="mb-0 ml-1 font-weight-bolder">
            {{ emissao.valor_global_emissao | moneyFormat }}
          </h2>
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="7">
        <b-card class="emission-card-border emission-card-bg">
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Data da Emissão</span>
            <strong>{{ emissao.data_emissao | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Data do Vencimento</span>
            <strong>{{ emissao.data_vencimento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span>Volume da série</span>
            <strong>{{ emissao.valor_global_emissao | moneyFormat }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Emissão</span>
            <strong>{{ emissao.numero_emissao || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Série</span>
            <strong>{{ emissao.numero_serie || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Emissor</span>
            <strong>{{ emissao.emissor || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span>Agente Fiduciário</span>
            <strong>{{ emissao.agente_fiduciario || '-' }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Código IF</span>
            <strong>{{ emissao.codigo_cetip || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span>ISIN</span>
            <strong>{{ emissao.codigo_isin || '-' }}</strong>
          </div>
        </b-card>
      </b-col>
      <b-col cols="5">
        <b-card class="emission-card-border emission-card-bg">
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">
              Juros pagos
              {{
                emissao.data_ultimo_pagamento
                  ? `(${formatDate(emissao.data_ultimo_pagamento)})`
                  : ''
              }}
            </span>
            <strong>{{ emissao.juros_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Amortização</span>
            <strong>{{ emissao.amort_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Amortização Extraordinária</span>
            <strong>{{ emissao.amex_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Duration</span>
            <strong>{{ emissao.desc_duracao_remanescente || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span class="mb-75">Período de Pagamentos de Juros</span>
            <strong>{{ emissao.periodo_pagamento_juros || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25">
            <span>Período de Amortizações</span>
            <strong>{{ emissao.periodo_pagamento_amort || '-' }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between mt-25" v-if="emissao.data_carencia == null">
            <span class="mb-75">Liquidação do último pagamento</span>
            <strong>{{ emissao.data_ultimo_pagamento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between mt-25" v-if="emissao.data_carencia == null">
            <span>Próximo pagamento</span>
            <strong>{{ emissao.data_proximo_pagamento | formatDate }}</strong>
          </div>

          <div class="d-flex justify-content-between"  v-if="emissao.data_carencia != null">
            <span>Carência</span>
            <strong>até {{ emissao.data_carencia | formatDate }}</strong>
          </div>

          <hr />
          <div class="d-flex justify-content-between mt-25">
            <span>Remuneração</span>
            <strong>{{ emissao.remuneracao || '-' }}</strong>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <notes-and-receipts v-if="emissao.despesas" :expenses="emissao.despesas" />
  </b-card>
</template>

<script>
import { BRow, BCol, BImg, BCard, BBadge, BButton } from 'bootstrap-vue'
import { moneyFormat } from '@/@core/comp-functions/data_visualization/datatable'
// eslint-disable-next-line import/no-cycle
import { formatDate } from '@core/utils/filter'
import NotesAndReceipts from '@/views/provider/components/NotesAndReceipts.vue'

export default {
  name: 'Emissoes',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BBadge,
    BButton,
    NotesAndReceipts,
  },
  filters: {
    moneyFormat,
    formatDate,
  },
  data() {
    return {
      selectedButton: 'price',
      emissao: {},
    }
  },
  async mounted() {
    const { data } = await this.$store.dispatch('provider/getEmission', this.$route.params.serie_id)
    this.emissao = data.data
  },
  methods: {
    formatDate,
  },
  computed: {
    emissaoImage() {
      const options = {
        cri: 'cri',
        cra: 'cra',
        deb: 'debenture',
        nc: 'nota_comercial',
      }

      // eslint-disable-next-line global-require, import/no-unresolved
      if (!this.emissao.tipo_emissao || !options[this.emissao.tipo_emissao.toLowerCase()]){
        return require('@/assets/images/recebíveis.svg')
      }

      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/images/${options[this.emissao?.tipo_emissao?.toLowerCase()]}.svg`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.max-w-6 {
  max-width: 6rem;
}
.max-w-10 {
  max-width: 10rem;
}

.emissoes-details-button {
  background-color: #3fe4c6 !important;
  border: 1px solid #1eb499 !important;
}

.btn-primary {
  color: #000000 !important;
}

.cs-select .vs__dropdown-toggle {
  border-radius: 0.357rem 0 0 0.357rem;
}

.cs-select .vs--open .vs__dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-icon {
  position: relative;
  display: inline-block;
}

.icon-position {
  position: absolute;
  right: 10px;
  top: 8px;
}

.pus-table th {
  background-color: #eff4f6;
  color: #242a32;
}

.emission-card-border {
  border-radius: 8px;
  border: 1px solid #becad4;
}

.emission-card-bg {
  border-radius: 8px;
  border: 1px solid #becad4;
  background: #f8fafc;
}
</style>
